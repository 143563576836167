function toCamelCase(str: string): string {
  return str.replace(/_([a-z])/g, (_, letter) => letter.toUpperCase());
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertKeysToCamelCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToCamelCase(item));
  } else if (obj !== null && typeof obj === 'object' && !Array.isArray(obj)) {
    return Object.fromEntries(
      Object.keys(obj).map((key) => [
        toCamelCase(key),
        convertKeysToCamelCase(obj[key]),
      ])
    );
  }
  return obj;
}

export { convertKeysToCamelCase };
