const toSnakeCase = (str: string): string => {
  return str.replace(/([A-Z])/g, (letter) => `_${letter.toLowerCase()}`);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function convertKeysToSnakeCase(obj: any): any {
  if (Array.isArray(obj)) {
    return obj.map((item) => convertKeysToSnakeCase(item));
  } else if (obj !== null && obj.constructor === Object) {
    return Object.fromEntries(
      Object.keys(obj).map((key) => [
        toSnakeCase(key),
        convertKeysToSnakeCase(obj[key]),
      ])
    );
  }
  return obj;
}

export { convertKeysToSnakeCase };
